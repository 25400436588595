import React, {useState, useEffect} from "react";
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import Layout from "../../../../components/layout";
import Seo from "../../../../components/seo";
import {StaticImage} from "gatsby-plugin-image";
import {graphql} from 'gatsby';
import rearLatch from "../../../../images/shared/rear-units/cleo-deadlatch.png";
import rearBolt from "../../../../images/shared/rear-units/cleo-deadbolt.png";
import flatCam from "../../../../images/shared/cams/cleo-flat.png";
import offsetCam from "../../../../images/shared/cams/cleo-offset.png";
import swatchBrushed from "../../../../images/shared/circle-brushed-nickle.png";
import swatchBlack from "../../../../images/shared/circle-matte-black.png";
import BANManagement from "../../../../components/pages/BANManagement";
import FaqSection from "../../../../components/pages/FaqSection";

import iconCleoStndKnob from "../../../../images/shared/lock-icons/cleo/icon-cleo-standard-knob.svg";
import iconCleoStndKnobOn from "../../../../images/shared/lock-icons/cleo/icon-cleo-standard-knob-on.svg";
import iconCleoStndHandle from "../../../../images/shared/lock-icons/cleo/icon-cleo-standard-handle.svg";
import iconCleoStndHandleOn from "../../../../images/shared/lock-icons/cleo/icon-cleo-standard-handle-on.svg";
import iconCleoStndAda from "../../../../images/shared/lock-icons/cleo/icon-cleo-standard-ADA.svg";
import iconCleoStndAdaOn from "../../../../images/shared/lock-icons/cleo/icon-cleo-standard-ADA-on.svg";
import iconCleoPullKnob from "../../../../images/shared/lock-icons/cleo/icon-cleo-pull-knob.svg";
import iconCleoPullKnobOn from "../../../../images/shared/lock-icons/cleo/icon-cleo-pull-knob-on.svg";
import iconCleoPullHandle from "../../../../images/shared/lock-icons/cleo/icon-cleo-pull-handle.svg";
import iconCleoPullHandleOn from "../../../../images/shared/lock-icons/cleo/icon-cleo-pull-handle-on.svg";
import iconCleoPullAda from "../../../../images/shared/lock-icons/cleo/icon-cleo-pull-ADA.svg";
import iconCleoPullAdaOn from "../../../../images/shared/lock-icons/cleo/icon-cleo-pull-ADA-on.svg";
import iconCleoOvalKnob from "../../../../images/shared/lock-icons/cleo/icon-cleo-oval-knob.svg";
import iconCleoOvalKnobOn from "../../../../images/shared/lock-icons/cleo/icon-cleo-oval-knob-on.svg";
import iconCleoOvalHandle from "../../../../images/shared/lock-icons/cleo/icon-cleo-oval-handle.svg";
import iconCleoOvalHandleOn from "../../../../images/shared/lock-icons/cleo/icon-cleo-oval-handle-on.svg";
import iconCleoOvalAda from "../../../../images/shared/lock-icons/cleo/icon-cleo-oval-ADA.svg";
import iconCleoOvalAdaOn from "../../../../images/shared/lock-icons/cleo/icon-cleo-oval-ADA-on.svg";
import BookConsultation from "../../../../components/pages/BookConsultation";


const IndexPage = () => {
  const {t} = useTranslation();

  // Body Style
  const [bodyStyleLocksOne, setBodyStyleLocksOne] = useState(true);
  const [bodyStyleLocksTwo, setBodyStyleLocksTwo] = useState(false);
  const [bodyStyleLocksThree, setBodyStyleLocksThree] = useState(false);
  const [bodyStyleLocksFour, setBodyStyleLocksFour] = useState(false);
  const [bodyStyleLocksFive, setBodyStyleLocksFive] = useState(false);
  const [bodyStyleLocksSix, setBodyStyleLocksSix] = useState(false);
  const [bodyStyleLocksSeven, setBodyStyleLocksSeven] = useState(false);
  const [bodyStyleLocksEight, setBodyStyleLocksEight] = useState(false);
  const [bodyStyleLocksNine, setBodyStyleLocksNine] = useState(false);

  const bodyStyleLabels = [
    t('cleo_label_1'),
    t('cleo_label_2'),
    t('cleo_label_3'),
    t('cleo_label_4'),
    t('cleo_label_5'),
    t('cleo_label_6'),
    t('cleo_label_7'),
    t('cleo_label_8'),
    t('cleo_label_9')
  ];

  const [bodyStyleLabel, setBodyStyleLabel] = useState(bodyStyleLabels[0]);

  const [brochure, setBrochure] = useState('https://cdn.mediavalet.com/usva/digilock/9EohaY4u3kCztNFNb4YV5Q/8iet275zI0mylb8z9QNszQ/Original/Digilock_Cleo%20Brochure.pdf');

  const resetBodyStyleImages = () => {
    setBodyStyleLocksOne(false);
    setBodyStyleLocksTwo(false);
    setBodyStyleLocksThree(false);
    setBodyStyleLocksFour(false);
    setBodyStyleLocksFive(false);
    setBodyStyleLocksSix(false);
    setBodyStyleLocksSeven(false);
    setBodyStyleLocksEight(false);
    setBodyStyleLocksNine(false);
  }

  const handleBodyStyleClick = (e) => {
    let data = e.target.getAttribute('data-value');
    resetBodyStyleImages();
    switch (data) {
      case 'bs-one':
        setBodyStyleLocksOne(true);
        setBodyStyleLabel(bodyStyleLabels[0]);
        break;
      case 'bs-two':
        setBodyStyleLocksTwo(true);
        setBodyStyleLabel(bodyStyleLabels[1]);
        break;
      case 'bs-three':
        setBodyStyleLocksThree(true);
        setBodyStyleLabel(bodyStyleLabels[2]);
        break;
      case 'bs-four':
        setBodyStyleLocksFour(true);
        setBodyStyleLabel(bodyStyleLabels[3]);
        break;
      case 'bs-five':
        setBodyStyleLocksFive(true);
        setBodyStyleLabel(bodyStyleLabels[4]);
        break;
      case 'bs-six':
        setBodyStyleLocksSix(true);
        setBodyStyleLabel(bodyStyleLabels[5]);
        break;
      case 'bs-seven':
        setBodyStyleLocksSeven(true);
        setBodyStyleLabel(bodyStyleLabels[6]);
        break;
      case 'bs-eight':
        setBodyStyleLocksEight(true);
        setBodyStyleLabel(bodyStyleLabels[7]);
        break;
      default:
        setBodyStyleLocksNine(true);
        setBodyStyleLabel(bodyStyleLabels[8]);
    }
  }

  useEffect(() => {
    // code
  }, [])

  return (
      <Layout>
        <Seo
            title={t('cleo_title_tag')}
            description={t('cleo_desc')}
        />
        <div className="products cleo-page">
          <section className={'hero-pages'}>
            <div className="container">
              <div className="grid-two-col">
                <div className="grid-two-col-item-single is-reversable">
                  <div>
                    <StaticImage
                        src="../../../../images/locks/product-pages/cleo/logo-gray-cleo.png"
                        loading={'lazy'}
                        width={142}
                        height={54}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Cleo logo"
                    />
                    <h1 dangerouslySetInnerHTML={{__html:t('cleo_headline2')}} />
                    <p>
                      <Trans>
                        cleo_headline2_copy
                      </Trans>
                    </p>
                  </div>
                  <BookConsultation />
                </div>
                <div className="grid-two-col-item-single">
                  <div>
                    <StaticImage
                        src="../../../../images/locks/product-pages/cleo/cleo-group.png"
                        loading={'lazy'}
                        width={480}
                        height={303}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Cleo Mechanical Combination Locker Lock + Electronic Key"
                        className={'cleo-family'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div id="pivot" className="section-delimeter container"/>
          <section className={'appearance-functionality cleo'}>
            <div className="container">
              <h1 className={'headline'} dangerouslySetInnerHTML={{__html: t('app_function_headline')}}/>
              <div className="grid-two-col cleo">

                <div className="grid-two-col-item-single center locks-container">
                  <div className="locks revealer cleo">
                    <div className={'icons show'}>
                      <div>
                        <img src={bodyStyleLocksOne ? iconCleoStndKnobOn : iconCleoStndKnob}
                             className={bodyStyleLocksOne ? 'lock-icon selected' : 'lock-icon'}
                             onClick={handleBodyStyleClick}
                             data-value={'bs-one'}
                             width={'32'}
                             height={'44'}
                             alt="keypad standard"/>
                      </div>
                      <div>
                        <img src={bodyStyleLocksTwo ? iconCleoStndHandleOn : iconCleoStndHandle}
                             className={bodyStyleLocksTwo ? 'lock-icon selected' : 'lock-icon'}
                             onClick={handleBodyStyleClick}
                             data-value={'bs-two'}
                             width={'32'}
                             height={'44'}
                             alt="rfid standard"/>
                      </div>
                      <div>
                        <img src={bodyStyleLocksThree ? iconCleoStndAdaOn : iconCleoStndAda}
                             className={bodyStyleLocksThree ? 'lock-icon selected' : 'lock-icon'}
                             onClick={handleBodyStyleClick}
                             data-value={'bs-three'}
                             width={'40'}
                             height={'44'}
                             alt="rfid standard"/>
                      </div>
                      <div>
                        <img src={bodyStyleLocksFour ? iconCleoPullKnobOn : iconCleoPullKnob}
                             className={bodyStyleLocksFour ? 'lock-icon selected' : 'lock-icon'}
                             onClick={handleBodyStyleClick}
                             data-value={'bs-four'}
                             width={'32'}
                             height={'58'}
                             alt="rfid standard network"/>
                      </div>
                      <div>
                        <img src={bodyStyleLocksFive ? iconCleoPullHandleOn : iconCleoPullHandle}
                             className={bodyStyleLocksTwo ? 'lock-icon selected' : 'lock-icon'}
                             onClick={handleBodyStyleClick}
                             data-value={'bs-five'}
                             width={'32'}
                             height={'58'}
                             alt="rfid standard"/>
                      </div>
                      <div>
                        <img src={bodyStyleLocksSix ? iconCleoPullAdaOn : iconCleoPullAda}
                             className={bodyStyleLocksThree ? 'lock-icon selected' : 'lock-icon'}
                             onClick={handleBodyStyleClick}
                             data-value={'bs-six'}
                             width={'40'}
                             height={'58'}
                             alt="rfid standard"/>
                      </div>
                      <div>
                        <img src={bodyStyleLocksSeven ? iconCleoOvalKnobOn : iconCleoOvalKnob}
                             className={bodyStyleLocksThree ? 'lock-icon selected' : 'lock-icon'}
                             onClick={handleBodyStyleClick}
                             data-value={'bs-seven'}
                             width={'31'}
                             height={'42'}
                             alt="rfid standard"/>
                      </div>
                      <div>
                        <img src={bodyStyleLocksEight ? iconCleoOvalHandleOn : iconCleoOvalHandle}
                             className={bodyStyleLocksThree ? 'lock-icon selected' : 'lock-icon'}
                             onClick={handleBodyStyleClick}
                             data-value={'bs-eight'}
                             width={'31'}
                             height={'42'}
                             alt="rfid standard"/>
                      </div>
                      <div>
                        <img src={bodyStyleLocksNine ? iconCleoOvalAdaOn : iconCleoOvalAda}
                             className={bodyStyleLocksThree ? 'lock-icon selected' : 'lock-icon'}
                             onClick={handleBodyStyleClick}
                             data-value={'bs-nine'}
                             width={'40'}
                             height={'44'}
                             alt="rfid standard"/>
                      </div>
                    </div>

                  </div>
                  <div className="locks">
                    <div className={'body-locks-container'}>
                      <StaticImage
                          className={bodyStyleLocksOne ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/cleo/cleo-standard-knob.png"
                          loading={'lazy'}
                          width={143}
                          height={206}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Cleo Standard Knob"
                      />
                      <StaticImage
                          className={bodyStyleLocksTwo ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/cleo/cleo-standard-handle.png"
                          loading={'lazy'}
                          width={143}
                          height={206}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Cleo Standard Handle"
                      />
                      <StaticImage
                          className={bodyStyleLocksThree ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/cleo/cleo-standard-ada.png"
                          loading={'lazy'}
                          width={143}
                          height={206}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Cleo Standard ADA"
                      />
                      <StaticImage
                          className={bodyStyleLocksFour ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/cleo/cleo-pull-knob.png"
                          loading={'lazy'}
                          width={143}
                          height={206}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Cleo Pull Knob"
                      />
                      <StaticImage
                          className={bodyStyleLocksFive ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/cleo/cleo-pull-handle.png"
                          loading={'lazy'}
                          width={143}
                          height={206}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Cleo Pull Handle"
                      />
                      <StaticImage
                          className={bodyStyleLocksSix ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/cleo/cleo-pull-ada.png"
                          loading={'lazy'}
                          width={143}
                          height={206}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Cleo Pull ADA"
                      />
                      <StaticImage
                          className={bodyStyleLocksSeven ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/cleo/cleo-oval-knob.png"
                          loading={'lazy'}
                          width={143}
                          height={206}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Cleo Oval Knob"
                      />
                      <StaticImage
                          className={bodyStyleLocksEight ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/cleo/cleo-oval-handle.png"
                          loading={'lazy'}
                          width={143}
                          height={206}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Cleo Oval Handle"
                      />
                      <StaticImage
                          className={bodyStyleLocksNine ? 'float-lock show' : 'float-lock'}
                          src="../../../../images/locks/product-pages/cleo/cleo-oval-ada.png"
                          loading={'lazy'}
                          width={143}
                          height={206}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Cleo Oval ADA"
                      />
                    </div>
                    <span className={'body-style-label'}>{bodyStyleLabel}</span>
                    <span className={'body-style-brochure'}>
                      <a href={brochure} target={'_blank'} className={'cleo-brochure-button'}>
                        <Trans>download_brochure</Trans>
                      </a>
                      </span>
                  </div>
                </div>

              </div>
            </div>
          </section>
          <div className="section-delimeter container"/>
          <section className={'cleo-key'}>
            <div class="container">
              <h1><Trans>cleo_key_headline</Trans></h1>
              <p><Trans>cleo_key_headline_copy</Trans></p>
              <div class="grid-two-col">
                  <div>
                    <StaticImage
                        src="../../../../images/locks/product-pages/cleo/cleo-flex-key.png"
                        loading={'lazy'}
                        width={378}
                        height={99}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Flex Key"
                    />
                  </div>
                  <div>
                    <h3><Trans>cleo_key_list_headline</Trans></h3>
                    <p><Trans>cleo_key_list_copy</Trans></p>
                    <ul>
                      <li dangerouslySetInnerHTML={{__html: t('cleo_key_list_1')}}/>
                      <li dangerouslySetInnerHTML={{__html: t('cleo_key_list_2')}}/>
                      <li dangerouslySetInnerHTML={{__html: t('cleo_key_list_3')}}/>
                    </ul>
                  </div>
              </div>
            </div>
          </section>
          <div className="section-delimeter container"/>
          <section className="page-benefits">
            <div className="container">
              <h1>
                <Trans>cleo_key_feat_bens</Trans>
              </h1>
              <div className="grid-two-col">
                <div className="grid-two-col-item-single is-reversable">
                  <div class="parent">
                    <div>
                      <h3 dangerouslySetInnerHTML={{__html: t('product_highlights')}}/>
                      <ul>
                        <li dangerouslySetInnerHTML={{__html: t('cleo_product_highlights_1')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('cleo_product_highlights_2')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('cleo_product_highlights_3')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('cleo_product_highlights_4')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('cleo_product_highlights_5')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('cleo_product_highlights_6')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('cleo_product_highlights_7')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('cleo_product_highlights_8')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('cleo_product_highlights_9')}}/>
                      </ul>
                    </div>
                    <div>
                      <h3><Trans>cleo_functionality</Trans></h3>
                      <ul>
                        <li dangerouslySetInnerHTML={{__html: t('cleo_functionality_list_one')}}/>
                      </ul>
                      <h3><Trans>cleo_installation</Trans></h3>
                      <ul>
                        <li dangerouslySetInnerHTML={{__html: t('cleo_installation_list_1')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('cleo_installation_list_2')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('cleo_installation_list_3')}}/>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className={'locking-finish-items'}>
                  <div>
                    <h3><Trans>finish_options</Trans></h3>
                    <div className="thumbs">
                      <div>
                        <img
                            src={swatchBrushed}
                            width={84}
                            height={84}
                            alt="brushed nickel finish"/>
                        <p className={'curve-swatch'}><Trans>brushed_nickel</Trans></p>
                      </div>
                      <div>
                        <img
                            src={swatchBlack}
                            width={84}
                            height={84}
                            alt="matte black finish"/>
                        <p className={'curve-swatch'}><Trans>matte_black</Trans></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className={'cleo-locking-options'}>
            <div class="container">
              <div>
                <h3><Trans>locking_options</Trans></h3>
                <div className="grid-four-col">
                  <div>
                    <img
                        src={rearBolt}
                        width={109}
                        height={101} alt="bolt rear unit"/>
                    <p className={'curve-deadlatch'}><Trans>dual_use_deadbolt</Trans></p>
                  </div>
                  <div>
                    <img
                        className={'curve-rearlatch'}
                        src={rearLatch}
                        width={109}
                        height={101}
                        alt="cam rear unit"/>
                    <p className={'curve-deadlatch'}>
                      <Trans>cleo_auto_relock_deadlatch</Trans><br/>(<Trans>assigned_use_only</Trans>)</p>
                  </div>
                  <div>
                    <img
                        src={flatCam}
                        width={109}
                        height={101} alt="flat cam"/>
                    <p><Trans>flat_cam</Trans></p>
                  </div>
                  <div>
                    <img
                        src={offsetCam}
                        width={109}
                        height={101} alt="flat cam"/>
                    <p><Trans>offset_cam</Trans></p>
                  </div>
                </div>
              </div>
              <br/><br/>
              <BookConsultation />
            </div>
          </section>
          <div id="pivot" className="section-delimeter container"/>
          <section className="faq-section">
            <FaqSection/>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
